import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet"
import IframeResizer from "iframe-resizer-react"

import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import PostWrapper from "../../modules/postWrapper"
import Post from "../../components/post"
import BlockContent from "../../components/page-builder/richText"
import Button from "../../components/button"
import Form from "../../modules/form"
import CTAButton from "../../components/ctaButton"

import styles from "./resource.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityResource(slug: { current: { eq: $slug } }) {
      _id
      title
      subtitle
      category {
        title
        slug {
          current
        }
      }
      excerpt
      thumbnailImages {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawThumbnailButton
      formTitle
      _rawFormSubtitle
      formLink
      _rawBody
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      pdfLabel
      pdf {
        asset {
          url
        }
      }
      referencedPdf {
        pdf {
          asset {
            url
          }
        }
      }
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    allResourceCats: allSanityResource {
      distinct(field: category___slug___current)
    }
  }
`

const ResourceTemplate = props => {
  const { data, location } = props
  const post = data && data.sanityResource
  const title = post.seo && post.seo.metaTitle ? post.seo.metaTitle : post.title
  const description =
    post.seo && post.seo.description ? post.seo.description : null
  const image =
    post.seo &&
    post.seo.openGraphImage &&
    post.seo.openGraphImage.asset !== null
      ? post.seo.openGraphImage.asset.url
      : null
  const referencedPdf = post?.referencedPdf?.pdf
  const pdf = post.pdf || referencedPdf
  const isImage = post.image && post.image.asset !== null

  return (
    <>
      <Layout>
        <Helmet>
          {post.seo && post.seo.noIndex && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <SEO title={title} description={description} image={image} />
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <Link to="/resources/">Resources</Link>
          <p>{post.title}</p>
        </Breadcrumbs>
        <section className={styles.resourceHeader}>
          <div className="grid">
            <div className="gridItem large-8 large-offset-2 medium-10 medium-offset-1">
              <h3>
                <span>
                  Category:{" "}
                  <Link
                    to={`/resources/?product=${post.category.slug.current}&content_type=all`}
                  >
                    {post.category.title}
                  </Link>
                </span>
              </h3>
              <h1 className={styles.resourceTitle}>{post.title}</h1>
              <h2>{post.subtitle}</h2>
            </div>
          </div>
        </section>
        <PostWrapper>
          {isImage && (
            <div>
              <Img
                className={styles.resourceImage}
                fluid={post.image.asset.fluid}
              />
              <div className={styles.imageGallery}>
                <div className={styles.imageGallery__images}>
                  {post.thumbnailImages && (
                    post.thumbnailImages.map((image) => (
                      <Img fluid={image.asset.fluid} />
                    ))
                  )}
                </div>
                {post._rawThumbnailButton &&
                  post._rawThumbnailButton.link.map(cta => (
                    <div className={styles.imageGallery__button}>
                      <CTAButton key={cta._key} blocks={cta} />
                    </div>
                  ))
                }
              </div>
            </div>
          )}
          <div className={`${isImage ? "" : styles.wideMainContent}`}>
            <Post>
              {post._rawBody && <BlockContent blocks={post._rawBody} />}
              {!post._rawBody && <p>{post.excerpt}</p>}
              {post.formLink && (
                <div className={styles.resourceForm}>
                  <Form
                    title={post.formTitle}
                    link={post.formLink}
                    subtitle={post._rawFormSubtitle}
                  />
                </div>
              )}
              {!post.formLink && pdf && pdf.asset !== null && (
                <div style={{ marginTop: `32px`, textAlign: `center` }}>
                  <Button
                    to={pdf.asset.url}
                    id={`resource-${post._id}`}
                    type="primary"
                    label={post.pdfLabel ? post.pdfLabel : "View resource"}
                    target
                    noFollow
                  />
                </div>
              )}
            </Post>
          </div>
        </PostWrapper>
      </Layout>
    </>
  )
}

export default ResourceTemplate
