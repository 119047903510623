import React from "react"

import styles from "./post.module.scss"

const Post = (props) => (
  <div className={styles.post}>
    {props.children}
  </div>
)

export default Post
