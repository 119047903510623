import React from "react"

import styles from "./postWrapper.module.scss"

const PostWrapper = (props) => {
  return (
    <section className={styles.postWrapper}>
      {props.children}
    </section>
  )
}

export default PostWrapper
